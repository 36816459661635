<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :isEnterprise="true"
            type="search" 
            name="plantCd" 
            v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육종류1 -->
          <c-select
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육종류2 -->
          <c-select
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="LBL0002934"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="LBL0002935"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정교육 여부 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0002800"
            name="legalEduYn"
            v-model="searchParam.legalEduYn">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 교육과정 목록 -->
    <c-table
      ref="table"
      title="LBL0002801"
      rowKey="eduCourseId"
      :columns="grid.columns"
      :selection="popupParam.type"
      :gridHeight="'100%'"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!-- 선택 -->
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-course-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            // 사업장
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            // 교육과정
            label: "LBL0002930",
            align: "left",
            style: 'width:200px',
            sortable: true,
            type: "link",
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            // 교육종류1
            label: "LBL0002933",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            // 교육종류2
            label: "LBL0002934",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            // 교육구분
            label: "LBL0002935",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "legalEducationFlagName",
            field: "legalEducationFlagName",
            // 법정교육여부
            label: "LBL0002800",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTimeName",
            field: "educationTimeName",
            // 교육시간
            label: "LBL0002932",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "estimatedEducationExpensesName",
            field: "estimatedEducationExpensesName",
            // 예상교육비
            label: "LBL0002803",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            // 학습목적
            label: "LBL0002937",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0002804",
            style: 'width:350px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        plantCd: null,
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        useFlag: 'Y',
        legalEduYn: null,
      },
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: false,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 150);
      if (height < 400) {
        height = 400;
      }
      this.grid.height = String(height) + 'px'
    },
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.course.list.url;
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0002806"; // 교육 과정 상세
      this.popupOptions.param = {
        eduCourseId: row.eduCourseId ? row.eduCourseId : '',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/edu/cc/educationCourseDetail.vue"}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      this.selectData = this.$refs['table'].selected;
      if (!this.selectData || this.selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', { 
          selectData: this.selectData,
        });
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', { 
          selectData: [row],
        });
      }
    },
  },
};
</script>
<style>
  c-table {
    flex:1;
    overflow: auto; 
  }
</style>